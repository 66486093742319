import React from "react";
import HeroSection from "./components/HeroSection";
import TimelineSection from "./components/TimelineSection";
import ContactSection from "./components/ContactSection";

const App = () => (
  <div>
    <HeroSection />
    <TimelineSection />
    <ContactSection />
  </div>
);

export default App;
